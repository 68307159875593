var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        { staticClass: "new-sino-box", staticStyle: { padding: "0" } },
        [
          _c(
            "el-header",
            {
              staticClass: "new-sino-header search-form",
              staticStyle: {
                display: "flex",
                "justify-content": "space-between",
              },
            },
            [
              _c(
                "div",
                [
                  _c("el-input", {
                    staticClass: "new-sino-input",
                    staticStyle: { width: "200px" },
                    attrs: {
                      placeholder: _vm.$t(
                        "cip.dc.DataAggregation.field.AggregationCoding"
                      ),
                      size: "mini",
                    },
                    model: {
                      value: _vm.queryList.aggCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryList, "aggCode", $$v)
                      },
                      expression: "queryList.aggCode",
                    },
                  }),
                  _c("el-input", {
                    staticClass: "new-sino-input",
                    staticStyle: { width: "200px" },
                    attrs: {
                      placeholder: _vm.$t(
                        "cip.dc.DataAggregation.field.Groupfields"
                      ),
                      size: "mini",
                    },
                    model: {
                      value: _vm.queryList.groupField,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryList, "groupField", $$v)
                      },
                      expression: "queryList.groupField",
                    },
                  }),
                  _c(
                    "el-button-group",
                    { staticStyle: { "margin-left": "10px" } },
                    [
                      _c("el-button", {
                        attrs: {
                          size: "mini",
                          type: "primary",
                          icon: "el-icon-search",
                        },
                        on: { click: _vm.onLoad },
                      }),
                      _c("el-button", {
                        attrs: {
                          size: "mini",
                          icon: "reset-refresh icon-refresh",
                        },
                        on: { click: _vm.czFn },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "el-button-group",
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "mini" }, on: { click: _vm.addFn } },
                        [_vm._v(_vm._s(_vm.$t("DataAggregation.btn.add")))]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: { click: _vm.deleteFn },
                        },
                        [_vm._v(_vm._s(_vm.$t("DataAggregation.btn.remove")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c("grid-layout", {
            ref: "gridLayout",
            attrs: {
              "data-total": _vm.total,
              page: _vm.page,
              tableOptions: _vm.tableOptions,
              tableData: _vm.tableData,
              tableLoading: _vm.tableLoading,
              gridRowBtn: _vm.gridRowBtn,
            },
            on: {
              "page-size-change": _vm.handleSizeChange,
              "page-current-change": _vm.handleCurrentChange,
              "page-refresh-change": _vm.onLoad,
              "gird-handle-select-click": _vm.selectionChange,
              "grid-see": _vm.SeeData,
              "grid-edit": _vm.handleEdit,
              "grid-romve": _vm.rowDel,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }